import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import DangerBox from "components/Indoor_Cameras/IN-8401_WQHD/Camera_Reset/DangerBox";
import PrimaryBox from "components/Indoor_Cameras/IN-8401_WQHD/Camera_Reset/PrimaryBox";
import NavButtons from 'components/Indoor_Cameras/IN-8401_WQHD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR IN-8401 2k+ WQHD Reset",
  "path": "/Indoor_Cameras/IN-8401_WQHD/Camera_Reset/",
  "dateChanged": "2018-01-22",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-8401 WQHD Outdoor IP Camera Factory Reset",
  "image": "./P_SearchThumb_IN-8401WQHD_Reset.webp",
  "social": "/images/Search/P_SearchThumb_IN-8401WQHD_Reset.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8401WQHD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-8401 2k+ WQHD Hardware Reset' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-8401 2k+ WQHD Outdoor IP Camera Factory Reset.' image='/images/Search/P_SearchThumb_IN-8401WQHD_Reset.png' twitter='/images/Search/P_SearchThumb_IN-8401WQHD_Reset.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Indoor_Cameras/IN-8401_WQHD/Camera_Reset/' locationFR='/fr/Indoor_Cameras/IN-8401_WQHD/Camera_Reset/' crumbLabel="Reset" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "restore-the-factory-settings-of-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#restore-the-factory-settings-of-your-camera",
        "aria-label": "restore the factory settings of your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Restore the factory settings of your camera`}</h2>
    <h3 {...{
      "id": "via-the-web-interface-of-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#via-the-web-interface-of-your-camera",
        "aria-label": "via the web interface of your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Via the web interface of your camera`}</h3>
    <p>{`If you still have access to your camera via your web browser, you can use the `}<Link to="/Web_User_Interface/1440p_Series/System/Reset/" mdxType="Link">{`Web interface to reset your camera`}</Link>{`.`}</p>
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1d3ef5ed80fa12029e89d64109d69181/d0143/IN-8401_WQHD_Reset.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA/0lEQVQoz41Sy27DMAzL///b1l1WoEC3Q7s0u8SvyPMrMQd5C5AEbR0DggzbpCiLDSor51xySgkxxtXZvdUg5/JgG0sgEykp0LZfmKbpKWmTnyibQVII/FhCiqGqsig03Q2m62C+u7IPWv2B/h8JIUBawVuqE+aUID/O+Dy+4/R2wPH1BcP1AniPcTDI1pYi6nqBvLUIMVQI+YLBRAhaF3WTc8CiZaM1Bq3gaEAIFcJla6s/XICcc7CWyj8qKesKa1MexxGWqIT3fnW3JW6wY8qcmZQHwn68V7RKuDX2bG4m3hbcrXDORAQpJfq+LxbSWj80+K6WWRlPl1ue41HLv5saspP1OWxjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d3ef5ed80fa12029e89d64109d69181/e4706/IN-8401_WQHD_Reset.avif 230w", "/en/static/1d3ef5ed80fa12029e89d64109d69181/d1af7/IN-8401_WQHD_Reset.avif 460w", "/en/static/1d3ef5ed80fa12029e89d64109d69181/7f308/IN-8401_WQHD_Reset.avif 920w", "/en/static/1d3ef5ed80fa12029e89d64109d69181/b1bd9/IN-8401_WQHD_Reset.avif 1025w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1d3ef5ed80fa12029e89d64109d69181/a0b58/IN-8401_WQHD_Reset.webp 230w", "/en/static/1d3ef5ed80fa12029e89d64109d69181/bc10c/IN-8401_WQHD_Reset.webp 460w", "/en/static/1d3ef5ed80fa12029e89d64109d69181/966d8/IN-8401_WQHD_Reset.webp 920w", "/en/static/1d3ef5ed80fa12029e89d64109d69181/d1bdf/IN-8401_WQHD_Reset.webp 1025w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d3ef5ed80fa12029e89d64109d69181/81c8e/IN-8401_WQHD_Reset.png 230w", "/en/static/1d3ef5ed80fa12029e89d64109d69181/08a84/IN-8401_WQHD_Reset.png 460w", "/en/static/1d3ef5ed80fa12029e89d64109d69181/c0255/IN-8401_WQHD_Reset.png 920w", "/en/static/1d3ef5ed80fa12029e89d64109d69181/d0143/IN-8401_WQHD_Reset.png 1025w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1d3ef5ed80fa12029e89d64109d69181/c0255/IN-8401_WQHD_Reset.png",
              "alt": "INSTAR IN-8401 2k+ WQHD Kamera Hardware Reset",
              "title": "INSTAR IN-8401 2k+ WQHD Kamera Hardware Reset",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "via-the-hardware-reset-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#via-the-hardware-reset-button",
        "aria-label": "via the hardware reset button permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Via the hardware reset button`}</h3>
    <p>{`To reset, press and hold the reset button `}<strong parentName="p">{`for 20 seconds`}</strong>{` (see below) while the camera is powered from the AC adapter and the LAN cable is removed.`}</p>
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "via-the-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#via-the-sd-card",
        "aria-label": "via the sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Via the SD card`}</h3>
    <p>{`If you no longer have access to the web interface and the reset button is damaged, you still have the reset on the SD card of your camera. Create a file for that and give it the name `}<strong parentName="p">{`reset`}</strong>{` (very important `}<strong parentName="p">{`without file extension`}</strong>{`!) - on Windows this is not that easy, so we have the necessary file `}<OutboundLink href="https://wiki.instar.com/dl/IN-9008HD/wps_reset.zip" mdxType="OutboundLink">{`here for download`}</OutboundLink>{`. The file is packed and must first be freed from the zip container before we can use it.`}</p>
    <h4 {...{
      "id": "reset-the-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#reset-the-camera",
        "aria-label": "reset the camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reset the camera`}</h4>
    <p><strong parentName="p">{`reset`}</strong>{` : Remove the SD card from your camera and connect the card to your computer via a card reader. Then place the file in the `}<strong parentName="p">{`root directory`}</strong>{` (i.e. directly on the card, not in a subfolder) of the memory card and then eject the SD card. To get the camera back to factory settings, please insert the SD card back into the card slot of the camera and then restart the camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/b97f6/reset_wps_by_sd_card.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABqElEQVQoz5VRy27TUBD1L/ITLJBoA+IHYMGGRb8CsUYskFBVlURUpCG0eUgFWkLzsGMnflxf3+fMvR4UW9AIFoijs5nHkc7MCeYb7sACAFpQ36L8cqa+RqAMANR7oD20nXCTB3lljCw45yXnxppKCmPt3RJRTeR97ZwHRGOs1tpaIKJ4mwVZstVSUl2DkHGY8LwwlQCpJCtFwVAqEFIUjGdFmeZFsq1ypkoOQiarVTD88WWUJaMsGWzD6+vxzauX5+vlYL3oR/N+NB/Ey4arT8nqbDnrh7fDTTiIl+frxenVRfDk7N2jy+P7g+OHk9OPV6/fHD17MDzpXJwcTnu/2D2c7NiZ9jrT3sGkezB+/3jcvffhbVBw67UBIQGw+n6rnz7f+0zD3xW6GtAb6xGJ6HMaB1VZoPPonERbzG6yF0elEFprIlJap3khteaV2OXhnUV03qP3RDRi2yDcZN65uiaLkEahWqw8kXeOiMBaxpiUUillmwgQcRdhY2RcpkEYxRXnRGSMob/wR8ht6ZtjduL1euO9R0TGWDunf8HXd+K49dOK6/8R/wR7T2UClWLthAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/e4706/reset_wps_by_sd_card.avif 230w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/d1af7/reset_wps_by_sd_card.avif 460w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/7f308/reset_wps_by_sd_card.avif 920w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/44a65/reset_wps_by_sd_card.avif 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/a0b58/reset_wps_by_sd_card.webp 230w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/bc10c/reset_wps_by_sd_card.webp 460w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/966d8/reset_wps_by_sd_card.webp 920w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/cb8de/reset_wps_by_sd_card.webp 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/81c8e/reset_wps_by_sd_card.png 230w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/08a84/reset_wps_by_sd_card.png 460w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/c0255/reset_wps_by_sd_card.png 920w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/b97f6/reset_wps_by_sd_card.png 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/c0255/reset_wps_by_sd_card.png",
              "alt": "INSTAR IN-8401 2k+ WQHD Kamera Hardware Reset",
              "title": "INSTAR IN-8401 2k+ WQHD Kamera Hardware Reset",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "wlan-integration-after-the-reset",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#wlan-integration-after-the-reset",
        "aria-label": "wlan integration after the reset permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WLAN integration after the reset`}</h4>
    <p><strong parentName="p">{`wps`}</strong>{` : To reconnect the camera to your Wi-Fi network after rebooting, you can create a file called `}<strong parentName="p">{`wps`}</strong>{` (very important `}<strong parentName="p">{`without file extension`}</strong>{`!) - it's not that easy on Windows, so we have the necessary file `}<OutboundLink href="https://wiki.instar.com/dl/IN-9008HD/wps_reset.zip" mdxType="OutboundLink">{`here for download provided`}</OutboundLink>{`. The file is packed and must first be freed from the zip container before we can use it. Place this file in the `}<strong parentName="p">{`root directory`}</strong>{` (ie directly on the card, not in a subfolder) of the memory card and then insert it back into the card slot of your camera. Then start the `}<Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/" mdxType="Link">{`WPS mode of your WLAN router`}</Link>{` and restart the camera. After restarting, the camera will be in WPS mode and will try to connect to your router.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      